<template>
  <div class="table-custom table-custom">
    <div>
      <table class="entire-table">
        <thead class="top-table">
          <tr>
            <th v-for="(item, index) in table_header" :key="index" role="columnheader" data-column-id="date" scope="col" class="header-table">{{item}}</th>
          </tr>
        </thead>
        <tbody >
          <tr v-for="(row, index) in table_body" :key="index" class="ipos-rows">
            <th v-for="(cel, key) in row" :key='key' scope="row" class="table-row"><span v-html="cel"></span></th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
  import TableNewIpos from "@/components/helpers/Table_news_ipos.vue";
  import Vue from 'vue';

  export default {
    name: 'TableNewIpos',
    props: {
      table_header: {type: Array},
      table_body: {type: Array},
    },
    data() {
      return {
      };
    },
    computed: {
    },
    mounted() {
      const MyComponentConstructor = Vue.extend(TableNewIpos);
      const table = new MyComponentConstructor();
    }  
  }
</script>



<style lang="scss">
.header-table{
  font-size: 12px;
  color: var(--color-text-primary);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  position: sticky;
  top: 0;
  background-color:var(--color-active-surface);
}

.top-table{
  width: 100%;
  height: 50px;
  position: sticky;
  top: 0;
  background-color:var(--color-active-surface);
}

.table-row{
  font-size: 12px;
  background-color: var(--color-surface);
  font-weight: normal;
  color: var(--color-text-primary);
}

.entire-table{
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  }
.ipos-rows{
  font-size: 12px;
  height: 40px;
  font-weight: normal;
  width: auto;
}

.table-custom{
  maxBodyHeight: 60vh;
  height:60vh;
  background-color: var(--color-background);
  overflow-y: scroll;
  border: 1px solid var(--color-text-primary);
  border-radius: 5px;
  width: 97.5%;
}
</style>
