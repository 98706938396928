<template>
  <div id="new-ipos" v-if="_g_IsUserLogged">
    <div v-if="_g_UserFeatures.permissions.next_IPOs_euro_zone || _g_UserFeatures.permissions.next_IPOs_usa_zone || _g_UserFeatures.permissions.next_IPOs_mundial">
      <div>
        <h2 :style="is_mobile_device ? 'margin-left: 20px; margin-top: 50px;' : 'margin-left: 100px;'">Próximas IPOs</h2>
      </div>
      <div :style="is_mobile_device ? 'margin-left: 20px;' : 'margin-left: 100px;'">
        <span class="filters-text">FILTROS</span>
      </div>
      <div :class="is_mobile_device ? 'filters-container-mobile' : 'filters-container'">
        <InputTextOutline @_emit_method_order_input="_getValueFromNewsIpos" :input_label="input_label" :input_helper="input_helper" :has_focus="has_focus" :icon_class="icon_class" :input_key_to_save_user_values="input_key_to_save_user_values" :input_class_character_counter="input_class_character_counter" :permission="permission" :icon_permission="icon_permission" :character_counter_permission="character_counter_permission" :helper_permission="helper_permission" :test_field_is_numeric="test_field_is_numeric" :length="length" :is_input_textarea="is_input_textarea" :class="is_mobile_device ? 'news-ipos-input-mobile' : 'news-ipos-input'"/> 
        <SelectOutline v-if="this._g_UserFeatures.permissions.next_IPOs_mundial" :helper_select_permission="false" :options="options" :component_class="component_class" :helper_text_select="helper_text_select" :default_value="default_value" @_emit_method_order_select="_selectValue" :class="is_mobile_device ? 'news-ipos-select-mobile' : 'news-ipos-select'"/>
        <SelectOutline v-else-if="this._g_UserFeatures.permissions.next_IPOs_euro_zone" :helper_select_permission="false" :options="options_euro" :component_class="component_class" :helper_text_select="helper_text_select" :default_value="default_value_euro" @_emit_method_order_select="_selectValue" :class="is_mobile_device ? 'news-ipos-select-mobile' : 'news-ipos-select'"/>
        <SelectOutline v-else-if="this._g_UserFeatures.permissions.next_IPOs_usa_zone" :helper_select_permission="false" :options="options_usa" :component_class="component_class" :helper_text_select="helper_text_select" :default_value="default_value_usa" @_emit_method_order_select="_selectValue" :class="is_mobile_device ? 'news-ipos-select-mobile' : 'news-ipos-select'"/>
        <Button :button_name="button_name" :button_key="button_key" @_emit_method_button="_clickButtonSearch" class="search-button"/>
      </div>
      <div :class="is_mobile_device ? 'results-ipos-container-mobile' : 'results-ipos-container'">
        <span class="filters-text" style="margin-top: 10px;">RESULTADOS: </span>
        <span class="results-text">{{counter_ipos}} Valores encontrados</span>
      </div>
      <div class="next-ipos-description-container">
        <img class="info-icon" src="@/views/assets/blue-info.png">
        <p class="next-ipos-description-text">Los valores que aquí se muestran son valores que van a salir al mercado en un futuro próximo, o que salieron al mercado como mucho hace 6 meses. Hay que tener en cuenta que estos valores no cumplen la metodología Eurekers.</p>
      </div>
      <div class="table-new-ipos">
          <TableCustom :table_header="table_header" :table_body="table_body"/>
      </div>
    </div>
    <div v-else class="not-allowed-new-ipos-container">
      <p class="not-allowed-new-ipos-title"> SIN ACCESO </p>
      <p class="not-allowed-new-ipos"> Su plan actual no tiene acceso a esta herramienta. <a href="/app/store" class="link-to-store"> Pulse aquí para cambiar de plan. </a> </p>
    </div>
  </div>
  <div id="main-container" class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-1-phone" v-else style="text-align: center;">
    <div v-if="!_g_User.is_basic_user" class="container-image mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--align-middle">      
        <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/ultimas_ipos.jpg">
        <div class="text-center">
          <h2>Últimas IPOs</h2>
          <p>Aquí podrás encontrar los Valores Eurekers más interesantes que acaban de salir a bolsa y las empresas más prometedoras que empezarán a cotizar próximamente.</p>
        </div>
        <div class="button-container">
          <button class="login-button" @click="_redirectToLogin()">LOGIN</button>
        </div> 
    </div>
    <div v-else class="unauthorized">
      <span>Usted no está autorizado para visitar esta página. Si la página a la que está accediendo es correcta, es posible que tenga que actualizar su plan.</span>
    </div>        
  </div>  
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import TableCustom from "@/components/helpers/Table_news_ipos.vue";
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
import SelectOutline from "@/components/helpers/SelectOutline.vue";
import Button from "@/components/helpers/Button.vue";

export default {
  mixins: [APICaller],  
  name: 'NewsIPOs',
  metaInfo() {
    return {
      title: 'Próximas IPOs Eurekers',
      meta:[
        { name: 'description', content: "Aquí podrás encontrar los Valores Eurekers más interesantes que acaban de salir a bolsa y las empresas más prometedoras que empezarán a cotizar próximamente"}
      ]
    }
  },
  props:{
  },
  components: {
    TableCustom,
    InputTextOutline,
    SelectOutline,
    Button,
  },
  data() {
    return {
        ipos: [],
        table_header: ["Ticker", "Empresa", "Fecha publicación"],
        table_body: [],
        counter_ipos: "",

        input_key_to_save_user_values:"filter-by-name",
        permission: true,
        icon_permission: true,
        test_field_is_numeric: false,
        is_input_textarea: false,
        input_helper: "Nombre",
        input_label: "Nombre",
        input_helper_permission: true,
        has_focus: false,
        icon_class: "input-icon-class",
        input_class_character_counter: "input-character-counter-class",
        character_counter_permission: false,
        helper_permission: false,
        length: 32,
        options: [
          {"name":"0", "value":"Todo(s)"}, 
          {"name":"AX", "value":"Australia"}, 
          {"name":"TO","value":"Canada"},
          {"name":"SS", "value":"China"},
          {"name":"L", "value":"England"}, 
          {"name":"PA", "value":"France"}, 
          {"name":"DE","value":"Germany"},
          {"name":"HK", "value":"HongKong"},
          {"name":"OL", "value":"Norway"}, 
          {"name":"KQ", "value":"South Korea"}, 
          {"name":"ST","value":"Sweden"},
          {"name":"TW", "value":"Taiwan"},
          {"name":"", "value":"USA"}
        ],
        options_euro: [
          {"name":"L", "value":"England"}, 
          {"name":"PA", "value":"France"}, 
          {"name":"DE","value":"Germany"},
        ],
        options_usa: [
          {"name":"TO","value":"Canada"},
          {"name":"", "value":"USA"}
        ],
        
        component_class: 'select-new-ipos',
        helper_text_select: '',
        default_value:'0',
        default_value_euro: 'L',
        default_value_usa: '',

        button_name: "Buscar",
        button_key: "button-buscar-key",

        filter_name: "",
        filter_country: "0",

    };
  },
  watch: {
  },
  created(){
  },
  mounted(){
    if(this._g_UserFeatures.permissions.next_IPOs_mundial){this.filter_country = '0'}
    else if(this._g_UserFeatures.permissions.next_IPOs_euro_zone){this.filter_country = 'L'}
    else if(this._g_UserFeatures.permissions.next_IPOs_usa_zone){this.filter_country = ""}
    this._getNewsIPOs();
  },
  computed: {
    ...mapGetters(["_g_IsUserLogged"]),
    ...mapGetters(["is_mobile_device", "_g_User"]), 
    ...mapGetters(["_g_UserFeatures"]),
  },
  methods: {
    _redirectToLogin() {
      window.location.href = "/user/login"; // Reemplaza la URL con la que desees redirigir
    },

    _getNewsIPOs: function(){
      let success = (response) => {    
        this.ipos = response.data;
        this.ipos.shift();    
        this._removeIposBefore1Year();
      };

      let url = '/api/v1/valores/ipos?name=' + this.filter_name + '&country=' + this.filter_country;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('188', 'Error recuperando carteras.');

      this._getAPICall(url, successHandler, failureHandler);

    },

    _prepareDataTable: function(){
        this.table_body = [];
        let counter = 0;
        this.ipos.forEach((element) => {
            let ipoToAdd = [];
            let ticker = "";
            let isLink = "";
            if(element.data[0].data.length < 20){
                ticker = element.data[0].data;
            }else{
                ticker = this._preprocessLinksTable(element.data[0].data, counter);
                //ticker = element.data[0].data;
            }
            let empresa = element.data[1].data;
            let fecha = element.data[2].data;

            ipoToAdd.push(ticker, empresa, fecha);
            this.table_body.push(ipoToAdd);
            counter++;
        })
    },

    _preprocessLinksTable: function(ticker){
        let array = ticker.split(">");
        array = array[1].split("<");
        return array[0];
    },

    // _preprocessLinksTable: function(ticker, index){
    //     var graf = document.getElementsByClassName("table-row");
    //     graf[index*3].innerHTML = ticker; 
    // },

    _getValueFromNewsIpos: function(value){
      this.filter_name = value[0];
    },

    _selectValue: function(value){
      this.filter_country = value[0];
    },
    _clickButtonSearch: function(){
      this._getNewsIPOs();
    },

    _removeIposBefore1Year: function(){
      this.ipos.forEach(element =>{
        let date = element.data[2].data
        date = date.split("-")
        date = date[2] + "-" + date[1] + "-" + date[0]
        date = new Date(date)
        date = date.getTime()
        element["timestamp"] = date;
      })
      var fecha_actual = new Date();
      let oneYearAgoTimestamp = fecha_actual.setYear(fecha_actual.getFullYear() - 1);
      this.ipos = this.ipos.filter(ipo => ipo["timestamp"] >=  oneYearAgoTimestamp)
      this.counter_ipos = this.ipos.length;      

      this._prepareDataTable()
    }
  }
}

</script>

<style lang="scss" scoped>

h2, .results-text {
  color: var(--color-text-primary);
}

.table-new-ipos{
    width: 90%;
    height: 100%;
    margin: auto;
}

.table-custom{
    width: 100%;
}
.entire-table{
    width: 100%;
}

.filters-container{
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
  margin-left: 100px;
}

.filters-container-mobile{
  margin-bottom: 10px;
  margin-left: 20px;
}

.news-ipos-input{
  width: 20%;
  margin-right: 30px;
  margin-top: 5px;
}

.news-ipos-input-mobile{
  width: 40%;
  margin-right: 30px;
  margin-top: 5px;
}

.news-ipos-select{
  width: 20%;
  margin-right: 30px;
  margin-top: 5px;
}

.news-ipos-select-mobile{
  width: 40%;
  margin-right: 30px;
  margin-top: 5px;
}

.filters-text{
  color: #bdbaba;
  font-weight: bold;
}

.search-button{
  margin-top:13px;
}
.results-text{
  font-weight: bold;
  font-size: 28px;
  margin-left: 25px;
}

#new-ipos{
  overflow:hidden;
}

.next-ipos-description-container{
  display: -webkit-box;
  width: 80%;
  margin-left: 90px;
  font-size: 12px;
  color: #31708f;
  border: 1px solid #bce8f1;
  border-radius: 15px;
  background: #d9edf7;
  margin-bottom: 20px;
}

.info-icon{
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 10px;
}

@media screen and (max-width: 400px){
  .next-ipos-description-container{
    width: 80% !important;
    margin-left: 20px !important;
  }
  .next-ipos-description-text{
    width: 80% !important;
  }
}

.results-ipos-container{
  display: flex;
  margin-left: 100px;
  margin-top:30px;
  margin-bottom: 50px;
}

.results-ipos-container-mobile{
  display: flex;
  margin-left: 20px;
  margin-top:30px;
  margin-bottom: 50px;
}

.container-image{
    /* Aplica un margen automático para centrar el div horizontalmente */
    margin: 0 auto;
    /* Ajusta el ancho máximo del contenedor */
    max-width: 800px;
    max-height: auto;
    
  }



  .container-image img{
    max-width: 500px;   
    max-height: 800px;
    margin: 20px;
    border-radius: 5px;
  }

  @media screen and (min-width: 768px) {
    .container-image img {
      width: 500px;
    }
  }

  .text-center {
    text-align: center;
    margin: 20px;
  }

  .container-image p {    
    text-align: justify;
  }

  .login-button{
    color: white;
    background-color: #00add8;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    width: 150px;
    margin-top: 20px;
    font-size: 14px;
  }
  .login-button:hover{
    background-color: #00bdec;
  }

  .not-allowed-new-ipos-container{
    text-align: center;
    margin-top: 50px;
  }
  
  .link-to-store{
    color: #35add8 !important;
  }

  .not-allowed-new-ipos-title{
    color: #FF9B00;
    font-weight: bold;
  }

</style>